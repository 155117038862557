// app/javascript/controllers/document_field_modal_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["errorContainer", "dependentsFields", "dependentTemplate", "button"]

  connect() {
    this.restoreCheckboxStates();
  }

  async open(event) {
    event.preventDefault();

    const modal = document.getElementById("candidate_modal");
    if (!modal) {
      console.error("Modal element not found!");
      return;
    }

    const documentId = event.currentTarget.dataset.modalDocumentId;
    const fieldIds = event.currentTarget.dataset.modalFieldIds;
    const loader = document.getElementById("loader");

    // Show the loader
    loader.classList.remove("hidden");

    try {
      if (documentId) {
        await this.loadDocument(documentId);
      } else if (fieldIds) {
        await this.loadFields(fieldIds);
      }
      modal.classList.remove("hidden");
      modal.classList.add("show");
    } catch (error) {
      console.error("Error opening the modal:", error);
    } finally {
      loader.classList.add("hidden");
    }
  }

  close(event) {
    if (event) event.preventDefault();

    const modal = document.getElementById("candidate_modal");
    if (!modal) return;

    modal.classList.add("hidden");
    modal.classList.remove("show");
  }

  async loadDocument(documentId) {
    try {
      const response = await fetch(`/candidates/admissions/load_document_fields?id=${documentId}`, {
        headers: { "Turbo-Frame": "document-modal" }
      });
      const html = await response.text();

      const turboFrame = document.getElementById("document-modal");
      turboFrame.innerHTML = html;
      turboFrame.classList.remove("hidden");
  
      this.restoreCheckboxStates();
    } catch (error) {
      console.error("Error loading the document:", error);
    }
  }

  async loadFields(fieldIds) {
    try {
      const response = await fetch(`/candidates/admissions/load_fields?field_ids=${fieldIds}`, {
        headers: { "Turbo-Frame": "document-modal" }
      });
      const html = await response.text();

      const turboFrame = document.getElementById("document-modal");
      turboFrame.innerHTML = html;
      turboFrame.classList.remove("hidden");

      this.restoreCheckboxStates();
    } catch (error) {
      console.error("Error loading the fields:", error);
    }
  }

  async submit(event) {
    event.preventDefault();

    const form = document.getElementById("documentForm");
    if (!form) {
      console.error("Form not found!");
      return;
    }
  
    const formData = new FormData(form);
    try {
      const response = await fetch(form.action, {
        method: "POST",
        body: formData,
        headers: { "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content }
      });

      if (response.ok) {
        const responseText = await response.text();
        document.getElementById("step-content").innerHTML = responseText;
        this.close();
      } else {
        const data = await response.json();
        this.displayErrors(data.errors);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      this.displayErrors(["Error submitting the form."]);
    }
  }

  displayErrors(errors) {
    const errorMessages = errors.map(error => `<li>${error}</li>`).join("");
    this.errorContainerTarget.innerHTML = `<ul>${errorMessages}</ul>`;
    this.errorContainerTarget.classList.remove("hidden");
  }

  addDependent(event) {
    event.preventDefault();

    // Clone the dependent template
    const dependentsSection = this.dependentsFieldsTarget;
    const template = this.dependentTemplateTarget.cloneNode(true);
    const timestamp = Date.now();

    // Ensure the cloned template is visible
    template.removeAttribute("data-document-field-modal-target");
    template.classList.remove("hidden");

    // Update all form elements within the template
    template.querySelectorAll("input, select, textarea, label").forEach((element) => {
      const originalId = element.id;
      const originalName = element.name;

      if (element.tagName === "LABEL") {
        const forAttribute = element.getAttribute("for");
        if (forAttribute) {
          element.setAttribute("for", `${forAttribute}_new_${timestamp}`);
        }
        return;
      }

      // Reset element values
      if (element.type === "checkbox" || element.type === "radio") {
        element.checked = false;
      } else if (element.type === "file") {
        // Handle file inputs
        if (originalName) {
          let newName;
          if (originalName.includes("front_file")) {
            newName = originalName.replace(
              /dependents\[front_file\]\[(\d+)\]/,
              `dependents[front_file][$1_new_${timestamp}]`
            );
          } else if (originalName.includes("back_file")) {
            newName = originalName.replace(
              /dependents\[back_file\]\[(\d+)\]/,
              `dependents[back_file][$1_new_${timestamp}]`
            );
          } else {
            newName = originalName.replace(
              /dependents\[file\]\[(\d+)\]/,
              `dependents[file][$1_new_${timestamp}]`
            );
          }
          element.name = newName;
        }
        element.value = '';
        // Clear file preview if exists
        const previewElement = template.querySelector(`[data-preview-for="${originalId}"]`);
        if (previewElement) {
          previewElement.innerHTML = '';
        }
      } else {
        // Handle all other inputs
        if (originalName) {
          const newName = originalName.replace(
            /(\w+)\[(\d+)\]/,
            `$1[$2_new_${timestamp}]`
          );
          element.name = newName;
        }
        element.value = "";
      }

      // Update IDs maintaining the original structure
      if (originalId) {
        const newId = `${originalId}_new_${timestamp}`;
        element.id = newId;
      }

      // Clear any validation classes or messages
      element.classList.remove("is-invalid", "is-valid");
      const errorContainer = template.querySelector(`[data-error-for="${originalId}"]`);
      if (errorContainer) {
        errorContainer.innerHTML = '';
        errorContainer.id = `error_${element.id}`;
      }
    });

    // Clear any dynamic content or display no file selected
    template.querySelectorAll('[id^="front-file-name"], [id^="back-file-name"], [id^="file-name"]').forEach(span => {
      span.textContent = "Nenhum arquivo selecionado";
    });

    // Clear any dynamic content or previews
    template.querySelectorAll('.preview-container').forEach(container => {
      container.innerHTML = '';
    });

    // Reset any custom state or data attributes
    template.querySelectorAll('[data-state]').forEach(element => {
      element.setAttribute('data-state', '');
    });

    // Append the updated template to the dependents section
    dependentsSection.appendChild(template);

    // Dispatch event for other controllers that might need to know about the new fields
    this.dispatch('dependentAdded', {
      detail: {
        template: template,
        timestamp: timestamp
      }
    });
  }

  toggleActive(event) {
    const clickedButton = event.currentTarget; // Botão clicado
    const container = clickedButton.parentElement; // Container dos botões

    // Remove a classe 'active' de todos os botões no container
    container.querySelectorAll("button").forEach((button) => {
      button.classList.remove("text-[#E85621]", "active");
      button.classList.remove("bg-[#F9FAFB]", "active");
      button.classList.add("text-gray-500");
      button.classList.add("bg-white");
    });

    // Adiciona a classe 'active' ao botão clicado
    clickedButton.classList.remove("text-gray-500");
    clickedButton.classList.remove("bg-white");
    clickedButton.classList.add("text-[#E85621]", "active");
    clickedButton.classList.add("bg-[#F9FAFB]", "active");
  }
  
  toggleRequiredFields() {
    document.querySelectorAll("input[type='checkbox'][id^='skip_upload_']").forEach((checkbox) => {
      checkbox.addEventListener("change", (event) => {
        const form = document.querySelector("#documentForm");
        const fields = form.querySelectorAll("input:not([type='checkbox']), select");
        if (event.currentTarget.checked) {
          fields.forEach((field) => {
            if (field.hasAttribute("required")) {
              field.dataset.wasRequired = "true";
              field.removeAttribute("required");
            }
          });
        } else {
          fields.forEach((field) => {
            if (field.dataset.wasRequired === "true") {
              field.setAttribute("required", "required");
            }
          });
        }
        localStorage.setItem(event.currentTarget.id, event.currentTarget.checked);
      });
    });
  }

  restoreCheckboxStates() {
    document.querySelectorAll("input[type='checkbox'][id^='skip_upload_']").forEach((checkbox) => {
      const storedValue = localStorage.getItem(checkbox.id);
      if (storedValue !== null) {
        checkbox.checked = storedValue === "true";
      }
      const form = document.querySelector("#documentForm");
      const fields = form.querySelectorAll("input:not([type='checkbox']), select");

      if (checkbox.checked) {
        fields.forEach((field) => {
          if (field.hasAttribute("required")) {
            field.dataset.wasRequired = "true";
            field.removeAttribute("required");
          }
        });
      } else {
        fields.forEach((field) => {
          if (field.dataset.wasRequired === "true") {
            field.setAttribute("required", "required");
          }
        });
      }
    });
  }
}
