// app/javascript/controllers/popover_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"];

  toggle(event) {
    event.stopPropagation();
    this.contentTarget.classList.toggle("hidden");
  }

  hide() {
    this.contentTarget.classList.add("hidden");
  }

  closeOnOutsideClick(event) {
    if (!this.element.contains(event.target)) {
      this.hide();
    }
  }
}
