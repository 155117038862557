import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="dialog"
export default class extends Controller {
  static targets = ["backdrop", "content"];

  show(event) {
    event.preventDefault();

    this.backdropTarget.classList.remove("hidden");
    this.backdropTarget.classList.add("flex");

    setTimeout(() => {
      this.contentTarget.classList.remove("opacity-0", "scale-95");
      this.contentTarget.classList.add("opacity-100", "scale-100");
    }, 5);
  }

  hide(event) {
    event.preventDefault();
    this.contentTarget.classList.remove("opacity-100", "scale-100");
    this.contentTarget.classList.add("opacity-0", "scale-95");

    setTimeout(() => {
      this.backdropTarget.classList.remove("flex");
      this.backdropTarget.classList.add("hidden");
    }, 300);
  }
}
