import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
    static targets = ["jobPosition", "department", "jobShift", "workplace", "branchOffice", "flow", "salary", "costCenter"];

    connect() {
        console.log("Admission Controller connected.");
    }

    async updateFields(event) {
        const jobPositionId = event.target.value;
        if (!jobPositionId) return;

        const response = await get(`/job_positions/${jobPositionId}/details`, {
            responseKind: "json",
        });

        if (response.ok) {
            const data = await response.json;

            this.departmentTarget.value = data.department_id || "";
            this.workplaceTarget.value = data.workplace_id || "";
            this.flowTarget.value = data.flow_id || "";
            this.branchOfficeTarget.value = data.branch_office_id || "";
            this.salaryTarget.value = data.salary || "";
            this.costCenterTarget.value = data.cost_center || "";
            this.hiringTypeTarget.value = data.hiring_type || "";
            this.workShiftTarget.value = data.work_shift || "";
            this.workScheduleTarget.value = data.work_schedule || "";
        }
    }
}
