import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  async open(event) {
    if (event) event.preventDefault();

    const modal = document.getElementById("upload_modal");
    if (!modal) {
      console.error("Modal element not found!");
      return;
    }

    const loader = document.getElementById("loader");
    loader.classList.remove("hidden");

    try {
      modal.classList.remove("hidden");
      modal.classList.add("show");
    } catch (error) {
      console.error("Error opening the modal:", error);
    } finally {
      loader.classList.add("hidden");
    }
  }

  close(event) {
    if (event) event.preventDefault();
    
    const modal = document.getElementById("upload_modal");
    if (!modal) return;

    modal.classList.add("hidden");
    modal.classList.remove("show");
  }
}
