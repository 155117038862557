import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "dropzone", "previews"];

  connect() {
    this.inputTarget.addEventListener("change", this.updatePreviews.bind(this));
    console.log("Upload controller connected");
  }

  // Drag and Drop
  highlight(e) {
    e.preventDefault();
    this.dropzoneTarget.classList.add("border-indigo-500", "bg-indigo-50");
  }

  unhighlight(e) {
    e.preventDefault();
    this.dropzoneTarget.classList.remove("border-indigo-500", "bg-indigo-50");
  }

  handleDrop(e) {
    e.preventDefault();
    this.inputTarget.files = e.dataTransfer.files;
    this.updatePreviews();
    this.unhighlight(e);

    if (this.inputTarget.files.length > 10) {
      alert("Máximo de 10 arquivos permitidos");
      this.inputTarget.value = "";
      return;
    }
  }

  // Preview dos arquivos
  updatePreviews() {
    this.previewsTarget.innerHTML = "";

    Array.from(this.inputTarget.files).forEach((file) => {
      const preview = this.createPreviewElement(file);
      this.previewsTarget.appendChild(preview);
    });
  }

  createPreviewElement(file) {
    const div = document.createElement("div");
    div.className =
      "flex items-center justify-between p-3 bg-gray-50 rounded-lg";

    div.innerHTML = `
      <div class="flex items-center space-x-2">
        <svg class="h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"/>
        </svg>
        <span class="text-sm text-gray-600">${file.name}</span>
      </div>
      <span class="text-sm text-gray-400">${this.formatFileSize(
        file.size
      )}</span>
    `;

    return div;
  }

  formatFileSize(bytes) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  }
}
